import { render, staticRenderFns } from "./CyberboardGoldPaisley.vue?vue&type=template&id=18e50734&scoped=true&"
import script from "./CyberboardGoldPaisley.vue?vue&type=script&lang=ts&"
export * from "./CyberboardGoldPaisley.vue?vue&type=script&lang=ts&"
import style0 from "./CyberboardGoldPaisley.vue?vue&type=style&index=0&id=18e50734&lang=scss&scoped=true&"
import style1 from "./CyberboardGoldPaisley.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18e50734",
  null
  
)

export default component.exports